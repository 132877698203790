import Image from "../Image";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Icon, Popover } from "@diemmea/dma-design-system";
import { useAppDispatch } from "../../redux/hooks";
import { useTranslation } from "react-i18next";
import { logoutUser } from "../../redux/reducers/authSlice";
import { useEffect, useState } from "react";
import { getEmail } from "../../services/storage";
import { Auth } from "aws-amplify";
import { AnimatePresence, motion, useCycle } from "framer-motion";
import { HEIGHT_MENU } from "../../services/utils";
import { INavbarItem, menu, settings } from "../../services/menu";
import logo from "./../../assets/images/logo-loyaltyone.svg"
import { render } from "react-dom";
import { Link } from "@mui/material";

const IconArrowDown = () => {
  return (
    <svg
      className="fill-current h-4 w-4 transform
                    ease-in-out"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" fill="white" />
    </svg>
  )
}

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useAppDispatch()
  const [userInfo, setUserInfo] = useState<any>({}) //TODO:
  const [open, cycleOpen] = useCycle(false, true);

  const currentLocation = location?.pathname.substring(1)

  //TODO: Handle with props
  const mockData = {
    key: "Contacts",
    title: "Contacts",
    items: [
      {
        key: "Contacts1",
        title: "Contacts",
        items: [],
        separator: true,
      },
      {
        key: "Marketing",
        title: "Marketing",
        items: [],
      },
      {
        key: "CMS",
        title: "CMS",
        items: [
          {
            key: "DesignTools",
            title: "Design Tools",
            items: [],
          },
          {
            key: "Website",
            title: "Website",
            items: [],
          },
          {
            key: "LandingPages",
            title: "Landing Pages",
            items: [],
          },
        ],
      },
    ],
  }
  const mockDatas: INavbarItem[] = [
    mockData
  ];


  const goToRoute = (route: string) => {
    navigate(route)
  }
  const isRouteSelected = (route: string) => {
    return currentLocation === route || currentLocation?.split("/")?.[0] === route
  }

  const initAuth = async () => {
    try {
      const _auth = await Auth.currentUserInfo()
      console.log(_auth)
      setUserInfo(_auth)
    } catch (error) {

    }
  }

  useEffect(() => {
    initAuth()
  }, [])


  const itemVariants = {
    closed: {
      opacity: 0,
      x: -100
    },
    open: { opacity: 1, x: 0 }
  };

  const sideVariants = {
    // closed: {
    //   transition: {
    //     staggerChildren: 0.2,
    //     staggerDirection: -1
    //   }
    // },
    open: {
      transition: {
        staggerChildren: 0.2,
        staggerDirection: 1
      }
    }
  };


  return (
    <div className="bg-secondaryDark z-50 px-12 sticky top-0 left-0 w-full" style={{ height: HEIGHT_MENU }}>
      <div className="h-full flex justify-between">

        {/** MENU DESKTOP */}
        <div id="menu" className="h-full lg:flex hidden">
          <div key={"logo"} className="group inline-block h-full align-middle	">
            <Link
              href={"/"}
              className="outline-none focus:outline-none h-full group-hover:bg-secondaryUltraDark transition-colors  flex mr-3 py-3"
            >
              <img src={logo} className="h-full transition-all px-2 py-1 hover:scale-90"
                style={{ maxWidth: 120, objectFit: "contain" }} />
            </Link>
          </div>
          {menu.map((firstLevel: INavbarItem, index: number) => {
            return (
              <div key={firstLevel.key} className="group inline-block h-full align-bottom	relative">
                <button className={`outline-none w-full focus:outline-none px-2 h-full group-hover:bg-secondaryUltraDark transition-colors ${isRouteSelected(firstLevel.key) ? "bg-secondaryUltraDark" : ""} flex items-center`}>
                  <span className={`px-2 text-white text-s h-full flex items-center justify-start ${firstLevel.items?.length ? "text-left" : "text-left"}`}>
                    {t(firstLevel.title)}
                  </span>
                  {firstLevel.items?.length && (
                    <span>
                      <IconArrowDown />
                    </span>
                  )}
                </button>
                {firstLevel.items?.length && (
                  <ul
                    className="z-50 bg-secondaryUltraDark py-3 transform scale-0 group-hover:scale-100 absolute text-nowrap min-w-full ease-in-out origin-top"
                  >
                    {firstLevel.items.map((secondLevel: INavbarItem) => {
                      return (
                        <div key={secondLevel.key}>
                          {!secondLevel.items?.length ? (
                            <li
                              key={secondLevel.key}
                              className="hover:bg-secondaryDark transition-colors text-white text-xs"
                            >
                              <a href={`/${secondLevel.key}`} onClick={() => { goToRoute(secondLevel.key) }} className="px-4 py-2 block w-full">{t(secondLevel.title)}</a>
                            </li>
                          ) : (
                            <li
                              key={secondLevel.key}
                              className="hover:bg-secondaryDark transition-colors"
                            >
                              <button className="outline-none focus:outline-none px-0 py-0 flex items-center">
                                <span className="pr-1 text-white text-xs">
                                  <a href="#" className="px-3 py-2 block w-full">{t(secondLevel.title)}</a>
                                </span>
                                {secondLevel.items.length && (
                                  <span className="mr-auto">
                                    <IconArrowDown />
                                  </span>
                                )}
                              </button>
                              {secondLevel.items.length && (
                                <ul style={{ right: 1.5 }}
                                  className="z-50 bg-secondaryDark h-full  absolute top-0 right-0 
                                                         	 ease-in-out origin-top-left
                                                        min-w-32
                                                        "
                                >
                                  {secondLevel.items.map((thirdLevel: INavbarItem) => {
                                    return (
                                      <li
                                        key={thirdLevel.key}
                                        className="hover:bg-secondaryUltraDark transition-colors text-white text-xs"
                                      >
                                        <a href={`/${thirdLevel.key}`} className="px-3 py-2 block w-full">{thirdLevel.title}</a>
                                      </li>
                                    )
                                  })}
                                </ul>
                              )}
                            </li>
                          )}
                        </div>
                      );
                    })}
                  </ul>
                )}
              </div>
            );
          })}
        </div>

        {/** MENU MOBILE */}
        <div id="mobile-menu" className="lg:hidden flex items-center">
          <AnimatePresence>
            {open && (
              <motion.aside
                className="absolute top-0 left-0 bg-white z-50 h-screen w-full md:w-96"
                initial={{ width: 0, opacity: 0 }}
                animate={{
                  opacity: 1
                }}
                exit={{
                  opacity: 0,
                  transition: { delay: 0.2, duration: 0.3 }
                }}
              >
                <motion.div
                  className="shadow-none md:shadow-aside h-full overflow-y-auto"
                  initial="closed"
                  animate="open"
                  exit="closed"
                  variants={sideVariants}
                >

                  <div className="flex items-center justify-between bg-gray-300 h-24">
                    <img src={logo} className="h-full w-full pl-8 object-contain" style={{ maxWidth: 200 }} />
                    <button type="button" onClick={() => {
                      document?.getElementById("root")?.classList?.remove("overflow-hidden")
                      cycleOpen();
                    }} className="absolute right-10">
                      <Icon icon="cross" color="white" />
                    </button>
                  </div>

                  <hr />

                  {menu.concat(settings).map((itemMenu, indexItemMenu) => (
                    [<motion.div
                      key={itemMenu.key}
                      // href={to}
                      // whileHover={{ scale: 1.1 }}
                      variants={itemVariants}
                      className={`pt-4 px-8`}
                    >
                      <div className="flex items-center">
                        <Icon icon={itemMenu.icon!} color="active" />
                        <h2 className="font-bold text-secondary ml-2">{
                          t(itemMenu.title)
                        }</h2>
                      </div>
                      <div className="flex flex-col">
                        {
                          (itemMenu?.items || []).map((itMenuChild) => {
                            return (
                              <motion.a
                                className="text-sm mt-2"
                                key={itMenuChild.key}
                                href={"#"}
                                // whileHover={{ scale: 1.1 }}
                                variants={itemVariants}
                                onClick={() => {
                                  cycleOpen();
                                  document?.getElementById("root")?.classList?.remove("overflow-hidden")
                                  goToRoute(itMenuChild.key)
                                }}
                              >
                                {t(itMenuChild.title)}
                              </motion.a>
                            )
                          })
                        }
                      </div>
                    </motion.div>, indexItemMenu === menu.length - 1 ? <hr className="mx-8 my-8" /> : null]

                  ))}
                </motion.div>
              </motion.aside>
            )}
          </AnimatePresence>
          <div className="btn-container">
            <button className="text-white" onClick={() => {
              document?.getElementById("root")?.classList?.add("overflow-hidden")
              cycleOpen();
            }}>
              <Icon icon="menu" color="white" />
            </button>
          </div>
        </div>



        {/** MENU SETTINGS */}
        <div id="menu-settings" className="h-full flex items-center">

          <div className="group flex h-full items-center justify-center">
            <Link
              href={"/settings"}
            >
              <Icon icon="cog" color="white" className="cursor-pointer rotate-0 transition-all hover:rotate-45" />
            </Link>
          </div>



          <div style={{ height: "80%", width: 1, backgroundColor: "white", opacity: 0.3 }} className="mx-4"></div>

          <div className="group flex h-full items-center justify-center">
            <Popover.PopoverWrapper>
              <Popover.PopoverTrigger>
                <img src="https://static.vecteezy.com/system/resources/thumbnails/003/337/584/small/default-avatar-photo-placeholder-profile-icon-vector.jpg" className="h-full rounded-full scale-90 hover:scale-100 transition-all" style={{ maxWidth: 32, objectFit: "contain" }} />
              </Popover.PopoverTrigger>
              <Popover.PopoverContent>
                <div>
                  <div className="flex">
                    <Image
                      alt="Avatar"
                      src={"https://static.vecteezy.com/system/resources/thumbnails/003/337/584/small/default-avatar-photo-placeholder-profile-icon-vector.jpg"} //TODO: Change img
                      classNameImage="h-full rounded-full"
                      classNameWrapper="w-16 h-16 rounded-full"
                    />
                    {/* <img src="https://api-na1.hubspot.com/userpreferences/v1/avatar/478b1db1254c0a25c066573d7efe630a/100" className="h-full rounded-full" style={{maxWidth:48,objectFit:"contain"}}/> */}
                    <div className="flex flex-col pl-3">
                      <span className="font-bold text-sm mb-2">{userInfo?.attributes?.name}</span>
                      <span className="text-sm mb-2">{userInfo?.attributes?.email}</span>
                      <Button isLink onClick={() => {
                        navigate("/settings")
                      }}><span className="text-sm">Profile and Preferences</span></Button>
                    </div>
                  </div>
                  <div className="flex justify-end items-center mt-4">
                    <Button isLink onClick={async () => {
                      try {
                        await dispatch(logoutUser())
                      } catch (error: any) {
                        console.log("Errore catch", error)
                      }
                    }}>Signout</Button>
                  </div>
                </div>
              </Popover.PopoverContent>
            </Popover.PopoverWrapper>

            {/* <button className="outline-none focus:outline-none h-full group-hover:bg-secondaryUltraDark  flex mr-3 p-3"
                onClick={()=>null}>
                  <img src="https://api.lorem.space/image/face?w=150&h=150" className="h-full rounded-full"/>
                </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
